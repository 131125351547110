import React from "react";
import { Link } from "react-router-dom";

function MarketingHero() {
  return (
    <div
      id="khalif-breadcrumb-wrap"
      className="khalif-breadcrumb-wrap hero-bg breadcrumb-bg text-center d-flex align-items-center avatar"
      style={{ backgroundImage: `url('/assets/img/Marketing1.jpg')` }}
    >
      <div className="container">
        <div className="row">
          <div className="offset-xl-2 col-xl-8">
            <div className="khalif-breadcrumb-content mt-5">
              <h2>Business Development</h2>
              <ul className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home 
                  </Link>
                </li>
                <li className="breadcrumb-item active text-white">Business Development</li>
              </ul>
              <p>
                Drive growth and efficiency through remote collaboration tools, task automation, and revenue optimization strategies tailored to your business needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketingHero;
