import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";

const NewDevelopment = () => {

  const { id } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchDevelopmentData = async () => {
      try {
        const userResponse = await axios.get(`https://affinityapi.dev-sh.xyz/api/quantilitics/${id}`, {
          headers: {
            Accept: "application/json",
          },
        });

        console.log(userResponse, "Success");

        if (userResponse.data.status === true) {
          toast.success("Please Check your details");
          setData(userResponse?.data?.quantilytic);
        console.log(userResponse?.data?.quantilytic, "Success");
        // Set empty array if data is undefined
        } else {
          toast.error("Data not found");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    };


    window.scrollTo(0, 0);
    fetchDevelopmentData();
  }, [id]);

  useEffect(() => {
    const initializeCarousel = () => {
      // Ensure that the element exists before accessing it
      const carouselElement = document.getElementById('carouselExampleIndicators');
      if (carouselElement) {
        // Initialize the Bootstrap Carousel
        new window.bootstrap.Carousel(carouselElement);
      } else {
        console.error('Carousel element not found.');
      }
    };

    // Run the initialization function after the DOM is fully loaded
    document.addEventListener('DOMContentLoaded', initializeCarousel);

    // Clean up event listener to prevent memory leaks
    return () => {
      document.removeEventListener('DOMContentLoaded', initializeCarousel);
    };
  }, []);

  return (
    <>

      <div
        id="khalif-breadcrumb-wrap"
        className="khalif-breadcrumb-wrap breadcrumb-bg text-center d-flex align-items-center avatar"
        style={{ backgroundImage: `url('/assets/img/Portfolio.png')` }}
      >
        <div className="container">
          <div className="row">
            <div className="offset-xl-2 col-xl-8">
              <div className="khalif-breadcrumb-content">
              <h2><strong>{data?.title}</strong></h2>
                <p className="text-white">{data?.intro}</p>
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/" title="Home">
                      Home 
                    </Link>
                  </li>
                  <li className="breadcrumb-item active text-white">Case Studies</li>
                </ul>
                {/* <p>
                In a recent e-commerce platform overhaul, we boosted performance
                and user experience through modern technology and agile
                development.
              </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container dark-bg-1 flex-min-height-box mt-100 mb-100">
        <div className=" row ">
        
            <div className="row  project-content top-padding-60 bottom-padding-60">
              <div className="col-lg-6">
                <div className="content-right-margin-20">
                <h2 className="text-color-4 fs-1 title-style text-black">
                  <strong>DESCRIPTION:</strong>
                </h2>
                <p
                  className="p-style-medium text-color-3 text-black fs-3"
                  style={{ letterSpacing: 1 }}
                >
                  {data?.description}
                </p>

                </div>
              </div>
              <div className=" col-lg-6 mb-5 avatar usa_mlt">
                <div className="content-left-margin-20 ">
                  <div className="box1 ">
                    <img className="position-absolute" alt="Android" src="/assets/img/laptop2.webp" />
                    <div className="slider">

                      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        {/* <div className="carousel-indicators">
                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div> */}
                        <div className="carousel-inner position-relative"  >
                        { data?.all_images?.map((item, index) => (
                            <div key={index}>
                              <div className={`carousel-item ${index === 0 ? 'active mr-0' : ' '}`} data-bs-interval="5000" >
                                <img src={item} alt={`Slide ${index}`} className="d-block usa_slide" />
                              </div>
                              {/* <div className="carousel-item" >
      <img  src={image.value}
                          alt={`Slide ${imageIndex}`} className="d-block w-75 usa_slide"/>
    </div> */}
                            </div>
                          ))}
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

           <div className="mt-5 row">
           <div className="col-lg-4">
              <h3 className="text-black">Technology Stack App:</h3>
                <p className="text-black">{data?.technology_stack_app}</p>
                <h3 className="text-black">Database:</h3>
                <p className="text-black">{data?.database}</p>
              </div>
              <div className="col-lg-4">
              <h3 className="text-black">Technology Stack APIs:</h3>
                <p className="text-black">{data?.technology_stack_appis}</p>

                <h3 className="text-black">Third Party:</h3>
                <p className="text-black">{data?.third_party}</p>
              </div>
              <div className="col-lg-4">
              <h3 className="text-black">Admin Panel & Website:</h3>
                <p className="text-black">{data?.admin_panel_and_website}</p>
                <h3 className="text-black">Category:</h3>
                <p className="text-black">{data?.category}</p>
              </div>
           </div>
            </div>
         
        </div>
      </div>

<style>
  {
    `
    .carousel-item {
      position: relative;
      display: none;
      float: left;
      width: 100%;
      margin-right: -100%; 
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: transform .6s ease-in-out
  }

  .carousel-control-prev {
    left: 43px;
}

.carousel-control-next {
  right: 20px;
}

.usa_slide {
  width: 100% !important;
  padding-top: 20px;
  margin-left: 2px;
  /* height: 51vh; */
}

.carousel {
  position: relative;
  width: 80%;
  left: 77px;
}

.position-absolute{
  width: 100%;
}


 @media only screen and (max-width: 1440px) {
  .usa_slide {
    width: 100% !important;
    padding-top: 19px !important;
    margin-left: 0px !important;
    height: 61vh !important;
}
.carousel-inner {
  position: relative;
  width: 100% !important;
  margin-left: 1px !important;
  overflow: hidden;
}
.position-absolute {
  position: absolute !important;
  width: 100% !important;
}
  } 

@media only screen and (max-width: 1024px) {
  .usa_slide {
      width: 83% !important;
      padding-top: 9px;
      margin-left: 51px;
      height: 50vh;
  }

  .position-absolute {
    position: absolute !important;
    width: 99% !important;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: -20px !important;
}
.usa_slide {
  width: 100% !important;
  margin-left: 0px;
  padding-top: 15px !important;
  height: 49vh !important;
}
  }

@media only screen and (max-width: 768px) {
  .usa_slide {
    width: 100% !important;
    padding-top: 18px !important;
    height: 65vh !important;
    margin-left: 0 !important;
  }
.content-left-margin-20 {
  float: inline-start;
  margin-left: 63px !important;
  margin-top: 22px !important;
}
.position-absolute {
  position: absolute !important;
  width: 80% !important;
  height: 69vh !important;
}
.carousel-inner {
  position: relative;
  width: 115% !important;
  overflow: hidden;
  margin-left: -10px !important;
}

}

@media only screen and (max-width: 425px) {
  .usa_slide {
    width: 96% !important;
    padding-top: 11px !important;
    margin-left: 4px !important;
    height: 42vh !important;
}
h3 {
  font-size: 20px;
}
          
            .hero-content h1 span {
              font-size: 21px;
              font-weight: 700;
              text-transform: uppercase;
              color: white;
              -webkit-text-stroke: 1px #fff;
          }
          
          h2.style-title {
            position: absolute;
            left: 24%;
            top: 15%;
            bottom: 0;
            right: 0;
            text-transform: uppercase;
            color: #ffffff24 !important;
            -webkit-text-stroke: 1px #585858;
            font-size: 99px;
            z-index: -1;
          }
          
          .usabtn {
            position: absolute;
            padding: 0px;
          }

          .carousel-control-prev {
            left: 43px;
            display: none;
        }
        .carousel-control-next {
          right: -50px;
          display: none;
      }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    margin-left: 0;
    width: 100% !important;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}
.carousel-inner {
  position: relative;
  width: 106% !important;
  margin-left: 43px !important;
  overflow: hidden;
}
.carousel {
  position: relative;
  width: 100%;
  left: 0;
}
.position-absolute {
  position: absolute !important;
  width: 98% !important;
  height: 45vh !important;
}
.content-left-margin-20 {
  float: inline-start;
  margin-left: 80px !important;
  margin-top: 22px !important;
}
.usa_mlt{
      margin-left: -77px;
}


}

@media only screen and (max-width: 375px) {
  .usa_slide {
    width: 94% !important;
    padding-top: 11px !important;
    margin-left: 0px !important;
    height: 42vh !important;
}
          
            .hero-content h1 span {
              font-size: 21px;
              font-weight: 700;
              text-transform: uppercase;
              color: white;
              -webkit-text-stroke: 1px #fff;
          }
          
          h2.style-title {
            position: absolute;
            left: 24%;
            top: 15%;
            bottom: 0;
            right: 0;
            text-transform: uppercase;
            color: #ffffff24 !important;
            -webkit-text-stroke: 1px #585858;
            font-size: 99px;
            z-index: -1;
          }
          
          .usabtn {
            position: absolute;
            padding: 0px;
          }

          .carousel-control-prev {
            left: 43px;
            display: none;
        }
        .carousel-control-next {
          right: -50px;
          display: none;
      }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    margin-left: 0;
    width: 100% !important;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}
.carousel-inner {
  position: relative;
  width: 114% !important;
  margin-left: 41px !important;
  overflow: hidden;
}
.carousel {
  position: relative;
  width: 100%;
  left: 0;
}
.position-absolute {
  width: 100%;
}
.content-left-margin-20 {
  float: inline-start;
  margin-left: 92px;
  margin-top: 29px;
}
.usa_mlt{
      margin-left: -70px;
}

}

@media only screen and (max-width: 320px) {
  .usa_slide {
    width: 95% !important;
    padding-top: 12px !important;
    margin-left: 0px !important;
    height: 42vh !important;
}
          
            .hero-content h1 span {
              font-size: 21px;
              font-weight: 700;
              text-transform: uppercase;
              color: white;
              -webkit-text-stroke: 1px #fff;
          }
          
          h2.style-title {
            position: absolute;
            left: 24%;
            top: 15%;
            bottom: 0;
            right: 0;
            text-transform: uppercase;
            color: #ffffff24 !important;
            -webkit-text-stroke: 1px #585858;
            font-size: 99px;
            z-index: -1;
          }
          
          .usabtn {
            position: absolute;
            padding: 0px;
          }

          .carousel-control-prev {
            left: 43px;
            display: none;
        }
        .carousel-control-next {
          right: -50px;
          display: none;
      }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    margin-left: 0;
    width: 100% !important;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}
.carousel-inner {
  position: relative;
  width: 126% !important;
  margin-left: 30px !important;
  overflow: hidden;
}
.carousel {
  position: relative;
  width: 100%;
  left: 0;
}
.position-absolute {
  width: 100%;
}
.content-left-margin-20 {
  float: inline-start;
  margin-left: 92px;
  margin-top: 29px;
}
.usa_mlt{
  margin-left: -60px;
}

}
    `
  }
</style>

    </>
  );
};

export default NewDevelopment;
