import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Button, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

// Define an array of blog posts
const blogPosts = [
  {
    title: "Marketing Strategies",
    image: "assets/img/blog/dlc.jpg",
  },
  {
    title: "Leverage agile frameworks to provide a robust",
    image: "assets/img/blog/m3.webp",
  },
  {
    title: "Development Knowledge",
    image: "assets/img/blog/dd.webp",
  },
  {
    title: "Unlocking the Entrepreneurial Mindset",
    image: "assets/img/blog/dd1.avif",
  },
];

function BlogCarousel() {
  const options = {
    autoplay: true,
    // nav: true,
    // navText: ["←", "→"],
    loop: true,
    margin: 0,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    autoplayTimeout: 2000,
  };

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setModalContent('');
    setShowModal(false);
  };

  return (
    <>
    <div className="khalif-blog-wrape pb-100 avatar">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title-wrape text-uppercase text-center position-relative mb-80">
              <h3>blog</h3>
              <h4>latest news</h4>
            </div>
            <OwlCarousel className="khalif-all-blog-list owl-carousel owl-theme" {...options}>
              {blogPosts.map((post, index) => (
                <div key={index} className="khalif-single-blog-wrape mb-30 magic-hover magic-hover__square">
                  <img src={post.image} alt="News" />
                  <div className="single-blog-content">
                    {/* <h4>
                      <Link to="#" className="post-title">
                        {post.title}
                      </Link>
                    </h4> */}

                    {post.title.length > 14 ? (
                      <h4>
                        <Link to="#" >
                          {post.title.substring(0, 14)}
                          {post.title.length > 14 && (
                            <span onClick={() => handleShowModal(post.title)} className="show-more-link fs-6">
                                 ...Show More
                            </span>
                          )}
                        </Link>
                      </h4>
                    ) : (
                      <h4><Link to="#">{post.title}</Link></h4>
                    )}
                    {/* <ul className="single-post-info">
                      <li className="blog-date">{post.date}</li>
                      <li className="blog-admin">
                        <Link to="#">by {post.admin}</Link>
                      </li>
                    </ul> */}
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>

     {/* Modal */}
     <Modal show={showModal} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Blog Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-content-text text-black">{modalContent}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>  
    <style>
      {
        `
        @media only screen and (max-width: 600px) {
          .post-title {
            font-size: 10px;
            font-weight: 300;
            line-height: 20px;
        }
        }
        `
      }
    </style>
    </>
  );
}

export default BlogCarousel;
