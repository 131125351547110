import React from "react";
import ColorSwitcher from "../components/colorSwitcher";
import HeroSec from "./index/heroSec";
import ShortAbout from "./index/shortAbout";
import ServicesSection from "./index/services";
// import VideoSection from "./index/video";
import PortfolioSection from "./index/portfolio";
import TestimonialSection from "./index/testimonial";
import BlogCarousel from "./index/blog";
import BrandCarousel from "./index/brand";
// import Pricing from "./index/pricing";
import InfluenceAccordion from "./services/influence/influenceAccordian";

function Index() {
    return (
      <>
		<ColorSwitcher />
		<HeroSec />
		<BrandCarousel />
		<ShortAbout />
		<ServicesSection />
		{/* <VideoSection /> */}
		{/* <Pricing /> */}
		<PortfolioSection />
		<TestimonialSection />
		<InfluenceAccordion />
		{/* <BlogCarousel /> */}
      </>
    );
  }
  
  export default Index;