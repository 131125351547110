import React from "react";
// import { Link } from "react-router-dom";

const ShortAbout = () => {
  return (
    <>
      <div className="khalif-about-wrape mb-100 avatar mb-0 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12">
              <div className="about-left-img-wrape">
                <div
                  className="about-img1 position-relative"
                  data-parallax='{"y": 50}'
                >
                  <div
                    className="about-exprience mb-20"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="2000"
                  >
                    <h3 className="text-black">
                      <span>
                        <b className="counter text-black">Case </b>
                      </span>
                      Studies
                    </h3>
                  </div>
                  <img
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    src="/assets/img/about/cc2.jpg"
                    alt="Marketing Agency"
                  />
                  {/* <div className="about-style-title text-uppercase text-purple">affinity</div> */}
                </div>
                <div
                  className="about-img2"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <img className="hdninmub" src="/assets/img/about/cs22.png" alt="Best Marketing Agency" />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
              <div className="about-short-brief " data-parallax='{"y": 70}'>
                <h2 className="text-uppercase text-purple">
                  We efficiently manage your <b style={{ color: "#000000" }}> Business </b> alongside our Dedicated <b style={{ color: "#000000" }}>Team</b>{" "}
                </h2>
                <p className="text-black">
                  Explore our portfolio to uncover a glimpse of our exceptional
                  work. Witness a selection of apps we've masterfully crafted and
                  effectively marketed, leveraging our in-house tools and
                  expertise
                </p>
                {/* <Link
                to="#"
                className="btn-2 bgc-2 text-center text-uppercase btn-tilt"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 95.91"
                  >
                    <path
                      d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                      transform="translate(-131.88 -418.11)"
                    />
                  </svg>
                </span>
                learn more
              </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {
          `
        @media only screen and (max-width: 600px) {
          img.hdninmub {
            display: none;
        }
        }

        /* @media only screen and (max-width: 1400px) {
          .usa_slide {    
          width: 70% !important;
          padding-top: 8px;
          margin-left: 61px;
          height: 54vh;
        
          }
          } */
        
          @media only screen and (max-width: 1024px) {
            .usa_slide {
                width: 83% !important;
                padding-top: 9px;
                margin-left: 51px;
                height: 50vh;
            }
            }
          
          @media only screen and (max-width: 768px) {
          .usa_slide {
            width: 65% !important;
            padding-top: 11px;
            margin-left: 61px;
            height: 59vh;
          }
          }
          
          @media only screen and (max-width: 425px) {
            .usa_slide {
              width: 83% !important;
              padding-top: 8px;
              margin-left: 45px;
              height: 43vh;
            }
            .hero-content h1 span {
              font-size: 29px;
              font-weight: 700;
              text-transform: uppercase;
              color: white;
              -webkit-text-stroke: 1px #fff;
          }
        
          h2.style-title {
            position: absolute;
            left: 24%;
            top: 15%;
            bottom: 0;
            right: 0;
            text-transform: uppercase;
            color: #ffffff24 !important;
            -webkit-text-stroke: 1px #585858;
            font-size: 104px;
            z-index: -1;
        }
        
        .about-short-brief{
          margin-left: 0px;
          margin-top: 11px;
        }

        .about-short-brief h2 {
          font-weight: 600;
          margin-left: 7px;
          margin-bottom: 70px;
          margin-top: 35px;
      }
      .about2-short-brief h2 {
        margin-left: 0px;
        width: 80%;
        font-size: 32px;
        line-height: 48px;
    }
          }
          
          @media only screen and (max-width: 375px) {
            .usa_slide {
              width: 84% !important;
              padding-top: 9px;
              margin-left: 39px;
              height: 37vh;
            }
        
            .hero-content h1 span {
              font-size: 25px;
              font-weight: 700;
              text-transform: uppercase;
              color: white;
              -webkit-text-stroke: 1px #fff;
          }
        
          h2.style-title {
            position: absolute;
            left: 24%;
            top: 15%;
            bottom: 0;
            right: 0;
            text-transform: uppercase;
            color: #ffffff24 !important;
            -webkit-text-stroke: 1px #585858;
            font-size: 100px;
            z-index: -1;
        }
          }
          
          @media only screen and (max-width: 320px) {
          .usa_slide {
            width: 82% !important;
            padding-top: 8px;
            margin-left: 37px;
            height: 31vh;
          }
        
          .hero-content h1 span {
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            color: white;
            -webkit-text-stroke: 1px #fff;
        }
        
        h2.style-title {
          position: absolute;
          left: 24%;
          top: 15%;
          bottom: 0;
          right: 0;
          text-transform: uppercase;
          color: #ffffff24 !important;
          -webkit-text-stroke: 1px #585858;
          font-size: 99px;
          z-index: -1;
        }
        `
        }
      </style>
    </>
  );
};

export default ShortAbout;
