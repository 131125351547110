import React from "react";
import { Link } from "react-router-dom";

function BlogsHero() {
  return (
    <div
      id="khalif-breadcrumb-wrape"
      className="khalif-breadcrumb-wrape breadcrumb-bg text-center d-flex align-items-center avatar"
      style={{ backgroundImage: `url('/assets/img/blogbg3.avif')` }}
    >
      <div className="container">
        <div className="row">
          <div className="offset-xl-2 col-xl-8">
            <div className="khalif-breadcrumb-content">
              <h2>blog list</h2>
              <ul className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active text-white">blog list</li>
              </ul>
              <p>
                In our new blog, we explore AI's impact, trends, and real-world
                applications, offering a glimpse into the future of this
                transformative technology{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogsHero;
