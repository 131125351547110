import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

const teamMembers = [
  {
    name: 'rahat hasan jhon',
    designation: 'artical writer',
    image: '/assets/img/team/1.jpg',
    socialLinks: [
      { icon: 'fa fa-facebook', link: '#' },
      { icon: 'fa fa-twitter', link: '#' },
      { icon: 'fa fa-instagram', link: '#' },
    ],
  },
  {
    name: 'rahat hasan jhon',
    designation: 'artical writer',
    image: '/assets/img/team/2.jpg',
    socialLinks: [
        { icon: 'fa fa-facebook', link: '#' },
        { icon: 'fa fa-twitter', link: '#' },
        { icon: 'fa fa-instagram', link: '#' },
    ],
  },
  {
    name: 'rahat hasan jhon',
    designation: 'artical writer',
    image: 'assets/img/team/3.jpg',
    socialLinks: [
      { icon: 'fa fa-facebook', link: '#' },
      { icon: 'fa fa-twitter', link: '#' },
      { icon: 'fa fa-instagram', link: '#' },
    ],
  },
  // Add other team members here
];

const AboutTeam = () => {
  const owlCarouselOptions = {
    loop: true,
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  };

  return (
    <div className="khalif-team-wrape team-bg pt-100 pb-100 mb-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title-wrape text-uppercase text-center position-relative mb-80">
              <h3 style={{fontSize: "80px;"}}>team</h3>
              <h4 style={{fontSize: "80px;"}}>our team</h4>
            </div>
            <OwlCarousel className="khalif-all-team-area owl-carousel owl-theme text-center" {...owlCarouselOptions}>
              {teamMembers.map((member, index) => (
                <div key={index} className="single-team-wrape">
                  <div className="team-img-wrape">
                    <img src={member.image} alt="Artificial Intelligence" />
                    <div className="team-title-desig-wrape">
                      <div className="team-popup-wrape">
                        <a className="popup-team" href={member.image}>
                          <svg
                            id="Layer_4"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 128 95.91"
                          >
                            <path
                              d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                              transform="translate(-131.88 -418.11)"
                            ></path>
                          </svg>
                        </a>
                      </div>
                      <h3 className="team-title">{member.name}</h3>
                      <h4 className="team-desig">{member.designation}</h4>
                    </div>
                  </div>
                  <ul className="team-social-wrape">
                    {member.socialLinks.map((link, idx) => (
                      <li key={idx}>
                        <a href={link.link}>
                          <i className={link.icon}></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
      <div className="khalif-more-team-member text-center">
        <Link to="#" className="btn-2 text-uppercase mt-70 bgc-1 btn-tilt">
          <span>
            <svg
              id="Layer_7"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 95.91"
            >
              <path
                d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                transform="translate(-131.88 -418.11)"
              ></path>
            </svg>
          </span>
          more member
        </Link>
      </div>
    </div>
  );
};

export default AboutTeam;
