import React, { useEffect } from "react";
import ContactHero from "./contactHero";
import ContactArea from "./contactArea";
// import VideoComponent from "../../components/video";
// import BrandsComponent from "../../components/brands";

function ContactIndex() {
  useEffect(()=>{
    document.title = "Quantilytics Contact Us"
  },[])
    return (
      <>
      <ContactHero />
      <ContactArea />
      {/* <VideoComponent /> */}
      {/* <BrandsComponent /> */}
      </>
    );
  }
  
  export default ContactIndex;