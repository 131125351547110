import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";

function BrandCarousel() {
  const options = {
    loop: true,
    margin: 0,
    autoplay: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    autoplayTimeout: 2000,
  };

  const brandImages = [
    "assets/img/brand/360.png",
    "assets/img/brand/Americren.png",
    "assets/img/brand/burgerism.png",
    "assets/img/brand/Career.png",
    "assets/img/brand/Delish.png",
    "assets/img/brand/Plant.png",
    "assets/img/brand/Tabdeeli.png",
    "assets/img/brand/techwaysolution.png",
    "assets/img/brand/walkers.png",
  ];

  return (
    <>
    <div className="khalif-brand-wrape pt-30 pb-200 avatar brand-bg"
    
    >
      <div className="container">
        <div className="col-12">
          <div className="section-title-wrape text-center text-uppercase position-relative mb-30">
            <h3 className="">brands</h3>
            <h4 className="text-black">popular brands</h4>
          </div>
           <OwlCarousel className="all-brand owl-carousel owl-theme" {...options}>
            {brandImages.map((image, index) => (
              <div key={index} className="single-brand">
                <Link to="#">
                  <img src={image} alt="Businesses" />
                </Link>
              </div>
            ))}
          </OwlCarousel> 
        </div>
      </div>
    </div>
    <style>
        {
        `
        .owl-theme img {
            min-height: 50px !important;
        }
        `
        }
    </style>
    </>
  );
}

export default BrandCarousel;
