import React from "react";
import AboutHero from "./aboutHero";
import ColorSwitcher from "../../components/colorSwitcher";
import AboutArea from "./aboutArea";
import AboutCounter from "./aboutCounter";
import AboutTeam from "./aboutTeam";
import BrandCarousel from "../index/brand";

function AboutIndex() {
    return (
      <>
		<ColorSwitcher />
        <AboutHero />
        <AboutArea />
        <AboutCounter />
        <AboutTeam />
        <BrandCarousel />
      </>
    );
  }
  
  export default AboutIndex;