import { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm() {
  const [email, setEmail] = useState('');
  const [cellNo, setCellNo] = useState('');
  const [description, setDescription] = useState('');
  const [fName, setFName] = useState('');

  const handleContactus = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', fName);
    formData.append('email', email);
    formData.append('phone', cellNo);
    formData.append('req', description);

    try {
      const response = await axios.post('https://affinityapi.dev-sh.xyz/api/contact', formData);

      console.log(response, 'Sign Up Successful');
      if (response.data.status === true) {
        toast.success("Email has been sent.", {
          position: "bottom-center",
           autoClose: 3000 
        });
        setFName('');
        setEmail('');
        setCellNo('');
        setDescription('');
      } else {
        toast.error('Sign Up Failed');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div id="khalif-contact-form-area" className="khalif-contact-form-area" data-aos="fade-up">
        <ToastContainer autoClose={3000} />
        <form className="khalif-contact-form" id="ajax-contact" onSubmit={handleContactus}>
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Name *"
                  value={fName}
                  onChange={(e) => setFName(e.target.value)}
                  required
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="E-mail *"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder="Phone Number *"
                  value={cellNo}
                  onChange={(e) => setCellNo(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <textarea
                  className="form-control"
                  name="message"
                  cols="30"
                  rows="8"
                  placeholder="Request"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 text-center">
                <button type="submit" className="submit-button">
                  <span style={{ fontsize: "35px" }}>↗ &nbsp;</span>
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <style>
        {`
          input.form-control::placeholder {
            color: white;
          }
          textarea.form-control::placeholder {
            color: white;
          }
          .btn-2:after {
            position: absolute;
            content: '';
            width: 215px;
            height: 120px;
            left: -10px;
            top: -10px;
            right: -10px;
            bottom: -10px;
            border-radius: 5%;
            border: 2px solid #595959;
            transition: all .3s;
            z-index: 1;
          }
          .btn-usa {
            width: 215px;
            height: 90px;
            color: #fff;
            font-size: 27px;
            font-weight: 600;
            font-family: 'Antonio', sans-serif;
            border-radius: 5%;
            padding: 50px 20px 50px 0px;
            position: relative;
            display: inline-block;
            transition: all .3s;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .submit-button {
            display: inline-block;
            padding: 10px 20px;
            font-size: 27px;
            font-weight: 600;
            color: white;
            background-color: #8183ff;
            border: solid 1px black;
            border-radius: 5%;
            cursor: pointer;
            transition: background-color 0.8s;
          }
          .submit-button:hover {
            background-color: #b4b5ff;
          }
          .text-center {
            text-align: center;
          }
        `}
      </style>
    </>
  );
}

export default ContactForm;
