import { Link } from "react-router-dom";

function HeroSec() {
  const openCalendlyPopup = () => {
    window.Calendly.initPopupWidget({
      url: "https://api.leadconnectorhq.com/widget/booking/io8V5ySgkA4MqsFxLaeK",
    });
  };

  <script src="https://link.msgsndr.com/js/form_embed.js" type="text/javascript"></script>
  return (
    <>
      {/* <!-- khalif hero area start --> */}
      <div class="khalif-hero-wrape hero-bg avatar">
        <div class="hero-content">
          <div class="container">
            <div class="row align-items-center">
              <div class="offset-xl-2 col-xl-10 offset-lg-2 col-lg-10 col-md-12">
                <ul class="khalif-hero-social">
                  <li>
                    <a href="https://www.facebook.com/AffinityDigitalAI">
                      facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/affinity_ai">twitter</a>
                  </li>
                  <li>
                    <a href="https://Instagram.com/affinitydigital_collective">
                      instagram
                    </a>
                  </li>
                  <li>
                    <a href="https://linkedin.com/company/affinity-digital-marketing-agency">
                      linkedin
                    </a>
                  </li>
                </ul>
                {/* <h2 class="style-title">affinity</h2> */}
                <h1 data-parallax='{"y": -60}'>
                  <span>Artificial Intelligence</span> <br />{" "}
                  <span>Startups</span> <br /> <span>MARKETING</span>
                </h1>
                <div class="hero-btn-para-wrape">
                  <div class="hero-btn position-relative usabtn">
                    <Link onClick={openCalendlyPopup} class="btn-2 text-center text-uppercase bgc-3 btn-tilt f-25 usa_a">
                      <span style={{ fontSize: "35px" }}>↗ &nbsp;</span>
                      Book an appointment
                    </Link>
                  </div>
                  <div class="hero-para">
                    <p>
                      Dive into the future with our cutting-edge Artificial
                      Intelligence, dynamic Startup Development, and impactful
                      Digital Marketing solutions. Transform potential into
                      success and let your brand soar with us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- khalif hero area end --> */}

      <style>
        {`
         .usabtn {
          position: absolute;
          padding: 50px;

      }
      .usa_a:hover {
        color:black;
      }

      .usa_a {
          text-decoration: none;
          background-image: linear-gradient(to right, #6400a4, #5e07b5, #5411c6, #411bd8, #1225eb);
          border: 2px solid white;
          color: aliceblue;
          border-radius: 5%;
          padding: 15px;
          position: relative;
      }

      .usa_a::after {
          position: absolute;
          content: '';
          bottom: -7px;
          top: -7px;
          left: -7px;
          right: -7px;
          background-color: transparent;
          border-radius: 5%;
          border: 4px solid #fff;
          margin: 0px;
          opacity: 1;
          transform-origin: center;
          animation: anim 2s linear infinite;
      }

      h2.style-title {
        position: absolute;
        left: 24%;
        top: 15%;
        bottom: 0;
        right: 0;
        text-transform: uppercase;
        color: #ffffff24 !important;
        -webkit-text-stroke: 1px #585858;
        font-size: 400px;
        z-index: -1;
    }

      .usa_a::before {
          position: absolute;
          content: '';
          bottom: -7px;
          top: -7px;
          left: -7px;
          right: -7px;
          background-color: transparent;
          border-radius: 5%;
          border: 4px solid #fff;
          margin: 0px;
          opacity: 0.8;
          transform-origin: center;
          animation: anim 1s linear 2s infinite;
      }

      @keyframes anim {
          from {
              margin: 0px;
              opacity: 1;
          }

          to {
              margin: -15px;
              opacity: 0;
          }
      }

      
/* @media only screen and (max-width: 1400px) {
  .usa_slide {    
  width: 70% !important;
  padding-top: 8px;
  margin-left: 61px;
  height: 54vh;

  }
  } */

  @media only screen and (max-width: 1024px) {
    .usa_slide {
        width: 83% !important;
        padding-top: 9px;
        margin-left: 51px;
        height: 50vh;
    }
    }
  
  @media only screen and (max-width: 768px) {
  .usa_slide {
    width: 65% !important;
    padding-top: 11px;
    margin-left: 61px;
    height: 59vh;
  }
  }
  
  @media only screen and (max-width: 425px) {
    .usa_slide {
      width: 83% !important;
      padding-top: 8px;
      margin-left: 45px;
      height: 43vh;
    }
    .hero-content h1 span {
      font-size: 29px;
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      -webkit-text-stroke: 1px #fff;
  }

  h2.style-title {
    position: absolute;
    left: 24%;
    top: 15%;
    bottom: 0;
    right: 0;
    text-transform: uppercase;
    color: #ffffff24 !important;
    -webkit-text-stroke: 1px #585858;
    font-size: 104px;
    z-index: -1;
}

.usabtn {
  position: absolute;
  padding: 0px;
}

.btn-2 {
  width: 215px;
  height: 100px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Antonio', sans-serif;
  border-radius: 5%;
  padding: 50px 0;
  position: relative;
  display: inline-block;
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-2:after {
  position: absolute;
  content: '';
  width: 240px; 
  height: 130px;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 5%;
  border: 2px solid #595959;
  transition: all .3s;
  z-index: 1;
}

.about-short-brief p {
  margin-bottom: 50px;
  margin-left: 0;
}

.about-short-brief p {
  margin-bottom: 50px;
  margin-left: 0;
}
  }
  
  @media only screen and (max-width: 375px) {
    .usa_slide {
      width: 84% !important;
      padding-top: 9px;
      margin-left: 39px;
      height: 37vh;
    }

    .hero-content h1 span {
      font-size: 25px;
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      -webkit-text-stroke: 1px #fff;
  }

  h2.style-title {
    position: absolute;
    left: 24%;
    top: 15%;
    bottom: 0;
    right: 0;
    text-transform: uppercase;
    color: #ffffff24 !important;
    -webkit-text-stroke: 1px #585858;
    font-size: 100px;
    z-index: -1;
}

.usabtn {
  position: absolute;
  padding: 0px;
}
  }
  
  @media only screen and (max-width: 320px) {
  .usa_slide {
    width: 82% !important;
    padding-top: 8px;
    margin-left: 37px;
    height: 31vh;
  }

  .hero-content h1 span {
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    -webkit-text-stroke: 1px #fff;
}

h2.style-title {
  position: absolute;
  left: 24%;
  top: 15%;
  bottom: 0;
  right: 0;
  text-transform: uppercase;
  color: #ffffff24 !important;
  -webkit-text-stroke: 1px #585858;
  font-size: 99px;
  z-index: -1;
}

.usabtn {
  position: absolute;
  padding: 0px;
}
  }
     
        `}
      </style>
    </>
  );
}

export default HeroSec;
