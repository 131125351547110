import React from 'react';

function AboutArea() {
  return (
    <div className="khalif-about2-wrape pt-100">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12">
            <div className="about2-left-img-wrape">
              <div className="about2-img1 position-relative" data-parallax='{"y": 50}'>
                <img src="assets/img/about/3.jpg" alt="Contact Now" />
                <div className="about2-style-title text-uppercase">khalif</div>
              </div>
              <div className="about2-img2 position-relative">
                <img src="assets/img/about/4.jpg" alt="Our Services" />
                <div className="about2-style-shape" data-parallax='{"x": 50}'><img src="assets/img/about/5.png" alt="Book an Appointment" /></div>
                <div className="about2-exprience" data-aos="fade-right" data-aos-delay="200">
                  experience
                  <h3><span><b className="counter">14</b>+</span>years</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
            <div className="about2-short-brief">
              <h2 className="text-uppercase" data-parallax='{"x": 50}'>we run agency <span>smartly</span> with our <span>team</span> member.</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <div className="khalif-accordian-wrapeer">
                <div className="accordion" id="accordionExample">
                  <div className="single-accordian-wrape">
                    <div className="single-accordian-header">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Accordion Item #1
                      </button>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                      </div>
                    </div>
                  </div>
                  <div className="single-accordian-wrape">
                    <div className="single-accordian-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Accordion Item #2
                      </button>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                      </div>
                    </div>
                  </div>
                  <div className="single-accordian-wrape">
                    <div className="single-accordian-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Accordion Item #3
                      </button>
                    </div>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutArea;
