import React, { useState } from "react";
import { Link } from "react-router-dom";

function PortfolioSection() {
  const portfolioItems = [
    {
      imageSrc: "./assets/img/folio/dodeliver.jpg",
      alt: "DoDeliver Logistics CRM System",
      category: "development",
      categories: ["Logistic CRM System", ""],
    },
    {
      imageSrc: "assets/img/folio/VirtualTraige.jpg",
      alt: "AI-Integrated Healthcare App",
      category: "marketing",
      categories: ["AI-Integrated Healthcare App", ""],
    },
    {
      imageSrc: "assets/img/folio/amnaya.png",
      alt: "Web And App Development",
      category: "marketing",
      categories: ["Web And App Development", ""],
    },
    {
      imageSrc: "assets/img/folio/360-Degree.jpg",
      alt: "E-Commerce Mobile App",
      category: "development",
      categories: ["E-Commerce App", ""],
    },
    {
      imageSrc: "assets/img/folio/Americren-Logo.jpg",
      alt: "Real Estate App",
      category: "marketing",
      categories: ["Real Estate App", ""],
    },
    {
      imageSrc: "assets/img/folio/House.png",
      alt: "Web App for Hiring Professionals",
      category: "development",
      categories: ["Web App for Hiring Professionals", ""],
    },
  ];

  const [activeTab, setActiveTab] = useState("all");

  const handleTabClick = (category) => {
    setActiveTab(category);
  };

  return (
    <>
      <div className="khalif-portfolio-wraper pt-100 pb-200 avatar">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <div className="section-title-wrape text-uppercase position-relative mb-80 text-purple">
                <h3>project</h3>
                <h4 className="text-purple">latest project</h4>
              </div>
              <ul className="landing-page-card portfolio-menu-wrape">
                <li
                  className={activeTab === "all" ? "active" : ""}
                  onClick={() => handleTabClick("all")}
                >
                  all
                </li>
                <li
                  className={activeTab === "development" ? "active" : ""}
                  onClick={() => handleTabClick("development")}
                >
                  development
                </li>
                <li
                  className={activeTab === "marketing" ? "active" : ""}
                  onClick={() => handleTabClick("marketing")}
                >
                  marketing
                </li>
              </ul>
            </div>
          </div>

          <div className="row portfolio-all-items">
            {portfolioItems.map((item, index) => {
              if (activeTab === "all" || item.category === activeTab) {
                return (
                  <div
                    key={index}
                    className={`col-lg-4 col-xl-4 col-md-6 col-sm-12 single-portfolio-item ${item.category}`}
                    data-tilt
                    data-aos="zoom-in"
                  >
                    <div className="single-folio-wraper">
                      <div className="folio-img-hvr-wrape">
                        <img src={item.imageSrc} alt="Grow your business" />
                        {/* <div className="portfolio-pop">
                          <a href={item.imageSrc}>
                            <i className="fa fa-plus"></i>
                          </a>
                        </div> */}
                      </div>
                      <div className="folio-hvr-title">
                        {/* <Link to="#">
                          <h3>{item.title}</h3>
                        </Link> */}
                        <div className="folio-ctg">
                          {item.categories.map((category, categoryIndex) => (
                            <Link key={categoryIndex} href="#">
                              {category}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div className="khalif-more-portfolio text-center">
          {/* <Link to="#" className="bgc-1 btn-2 text-uppercase mt-40 btn-tilt">
            <span>
              <svg
                id="Layer_4"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 95.91"
              >
                <path
                  d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                  transform="translate(-131.88 -418.11)"
                ></path>
              </svg>
            </span>
            more portfolio
          </Link> */}
        </div>
      </div>
      {/* <style>
        {
          `
          .btn-2 {
            margin-bottom: 106px !important;
        }
          `
        }
      </style> */}
    </>
  );
}

export default PortfolioSection;
