import React from "react";
import { Link } from "react-router-dom";

function Pricing() {

  const openCalendlyPopup = () => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/book-a-slot-now/affinitydigital?background_color=1a1a1a&text_color=ffffff",
    });
  };

  return (
    <>
      <div className="khalif-pricing-wrape pt-10 pb-10 avatar">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title-wrape text-center text-uppercase position-relative mb-100">
                {/* <h3>pricing</h3>
                <h4>pricing table</h4> */}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4"></div>
            <div className="col-xl-4 col-lg-4 col-md-4" data-aos="fade-right" data-aos-delay="200">
              <div className="single-price-area mb-30 bgc-2">
                <div className="price-btn pt-40">
                  <Link
                    className="btn-5 text-center text-uppercase magic-hover magic-hover__square"
                    to="#"
                    onClick={openCalendlyPopup}
                  >
                    Get a code
                    <span>
                      <svg
                        id="Layer_12"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 128 95.91"
                      >
                        <path
                          d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                          transform="translate(-131.88 -418.11)"
                        ></path>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4"></div>
            {/* <div
              className="col-xl-4 col-lg-4 col-md-4"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <div className="single-price-area mb-30 bgc-2 active">
                <div className="price-bg">
                  <img src="assets/img/pricing/1.png" alt="pricing-img" />
                </div>
                <div className="price-header">
                  <h2>
                    $69<span className="sep">/</span>
                    <span>month</span>
                  </h2>
                  <h4>standard plan</h4>
                </div>
                <div className="price-icon bgc-1">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 480.005 480.005"
                    style={{ enableBackground: "new 0 0 480.005 480.005" }}
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <g>
                          <path d="M240.003,48c4.418,0,8-3.582,8-8V8c0-4.418-3.582-8-8-8s-8,3.582-8,8v32C232.003,44.418,235.584,48,240.003,48z" />
                          <path
                            d="M141.075,36.656l16,27.704c2.184,3.841,7.068,5.184,10.909,3s5.184-7.068,3-10.909c-0.017-0.031-0.035-0.061-0.053-0.092
				l-16-27.704c-2.184-3.841-7.068-5.184-10.909-3c-3.841,2.184-5.184,7.068-3,10.909C141.039,36.595,141.057,36.626,141.075,36.656
				z"
                          />
                          <path
                            d="M76.567,106.875c0.03,0.018,0.061,0.035,0.092,0.053l27.704,16c3.812,2.234,8.713,0.956,10.947-2.856
				c2.234-3.812,0.956-8.713-2.856-10.947c-0.03-0.018-0.061-0.035-0.092-0.053l-27.704-16c-3.812-2.234-8.713-0.956-10.947,2.856
				C71.477,99.739,72.755,104.641,76.567,106.875z"
                          />
                          <path d="M96.003,192c0-4.418-3.582-8-8-8h-32c-4.418,0-8,3.582-8,8s3.582,8,8,8h32C92.421,200,96.003,196.418,96.003,192z" />
                          <path
                            d="M104.456,261.018c-0.031,0.018-0.062,0.036-0.093,0.054l-27.704,16c-3.841,2.183-5.185,7.067-3.002,10.908
				c2.183,3.841,7.067,5.185,10.908,3.002c0.031-0.018,0.062-0.036,0.093-0.054l27.704-16c3.841-2.183,5.185-7.067,3.002-10.908
				C113.181,260.179,108.297,258.835,104.456,261.018z"
                          />
                          <path
                            d="M403.44,277.126c-0.031-0.018-0.062-0.036-0.093-0.054l-27.704-16c-3.811-2.235-8.713-0.957-10.948,2.854
				c-2.235,3.811-0.957,8.713,2.854,10.948c0.031,0.018,0.062,0.036,0.093,0.054l27.704,16c3.811,2.235,8.713,0.957,10.948-2.854
				C408.529,284.262,407.251,279.361,403.44,277.126z"
                          />
                          <path d="M424.003,184h-32c-4.418,0-8,3.582-8,8s3.582,8,8,8h32c4.418,0,8-3.582,8-8S428.421,184,424.003,184z" />
                          <path
                            d="M371.651,124c1.404,0,2.784-0.37,4-1.072l27.704-16c3.841-2.184,5.184-7.068,3-10.909s-7.068-5.184-10.909-3
				c-0.031,0.017-0.061,0.035-0.092,0.053l-27.704,16c-3.826,2.209-5.137,7.102-2.928,10.928
				C366.152,122.475,368.793,124,371.651,124z"
                          />
                          <path
                            d="M311.993,67.291c0.003,0.002,0.006,0.004,0.009,0.005c3.827,2.207,8.72,0.894,10.927-2.934
				c0-0.001,0.001-0.002,0.001-0.002l16-27.704c2.235-3.811,0.957-8.713-2.854-10.948c-3.811-2.235-8.713-0.957-10.948,2.854
				c-0.018,0.031-0.036,0.062-0.054,0.093l-16,27.704C306.862,60.184,308.169,65.078,311.993,67.291z"
                          />
                          <path
                            d="M256.003,320c4.418,0,8-3.582,8-8v-88h16c17.673,0,32-14.327,32-32s-14.327-32-32-32c-17.673,0-32,14.327-32,32v16h-16
				v-16c0-17.673-14.327-32-32-32s-32,14.327-32,32s14.327,32,32,32h16v88c0,4.418,3.582,8,8,8s8-3.582,8-8v-88h16v88
				C248.003,316.418,251.584,320,256.003,320z M264.003,192c0-8.837,7.163-16,16-16s16,7.163,16,16s-7.163,16-16,16h-16V192z
				 M216.003,208h-16c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16V208z"
                          />
                          <path
                            d="M246.219,64.152c-70.611-3.392-130.603,51.099-133.995,121.71c-1.943,40.432,15.354,79.4,46.643,105.082
				c5.639,4.417,8.994,11.135,9.136,18.296V328c0.042,10.134,6.445,19.15,16,22.528V360c0.021,5.923,2.245,11.627,6.24,16
				c-8.32,9.044-8.32,22.956,0,32c-8.894,9.75-8.2,24.864,1.55,33.758c4.429,4.04,10.215,6.268,16.21,6.242
				c4.306,0.113,7.889-3.287,8.001-7.593c0.044-1.68-0.456-3.33-1.425-4.703c-0.253-0.489-0.553-0.953-0.896-1.384
				c-0.658-0.602-1.409-1.094-2.224-1.456c-0.232-0.12-0.464-0.216-0.704-0.312c-0.81-0.295-1.659-0.465-2.52-0.504
				c-0.08,0-0.152-0.048-0.232-0.048c-4.418,0-8-3.582-8-8s3.582-8,8-8h32c4.418,0,8-3.582,8-8s-3.582-8-8-8h-32
				c-4.418,0-8-3.582-8-8s3.582-8,8-8h32c4.418,0,8-3.582,8-8s-3.582-8-8-8h-32c-4.418,0-8-3.582-8-8v-8h40c4.418,0,8-3.582,8-8
				s-3.582-8-8-8h-48c-4.418,0-8-3.582-8-8v-18.76c-0.131-11.962-5.64-23.23-15-30.68c-47.788-39.274-54.689-109.852-15.415-157.64
				c22.466-27.336,56.528-42.448,91.871-40.76c59.107,3.516,105.536,51.957,106.544,111.16c0.287,34.052-15.024,66.363-41.56,87.704
				c-9.29,7.652-14.602,19.109-14.44,31.144V328c0,4.418-3.582,8-8,8h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h16v8
				c0,4.418-3.582,8-8,8h-8c-4.418,0-8,3.582-8,8s3.582,8,8,8h8c4.418,0,8,3.582,8,8s-3.582,8-8,8h-8c-4.418,0-8,3.582-8,8
				s3.582,8,8,8h8c4.418,0,8,3.582,8,8s-3.582,8-8,8h-40c-4.418,0-8,3.582-8,8s3.582,8,8,8h30.632
				c-4.417,12.497-18.129,19.047-30.626,14.63c-3.826-1.352-7.246-3.653-9.942-6.686c-2.938-3.3-7.996-3.594-11.296-0.656
				c-3.3,2.938-3.594,7.996-0.656,11.296c14.631,16.487,39.857,17.992,56.344,3.362c6.81-6.043,11.349-14.232,12.864-23.21
				c12.529-3.916,19.511-17.247,15.596-29.776c-1.04-3.329-2.801-6.388-5.156-8.96c8.32-9.044,8.32-22.956,0-32
				c3.995-4.373,6.219-10.077,6.24-16v-9.472c9.555-3.378,15.958-12.394,16-22.528v-17.832c-0.107-7.187,3.007-14.046,8.488-18.696
				c30.344-24.395,47.85-61.339,47.512-100.272C366.847,123.532,313.777,68.169,246.219,64.152z"
                          />
                        </g>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </div>
                <ul className="price-item-list">
                  <li>
                    <i className="fa fa-check"></i> web development
                  </li>
                  <li>
                    <i className="fa fa-check"></i> mobile development
                  </li>
                  <li>
                    <i className="fa fa-check"></i> advetising
                  </li>
                  <li>
                    <i className="fa fa-times"></i> graphic design
                  </li>
                  <li>
                    <i className="fa fa-times"></i> project management
                  </li>
                </ul>
                <div className="price-btn pt-40">
                  <Link
                    className="btn-5 text-center text-uppercase magic-hover magic-hover__square"
                    to="#"
                  >
                    buy now
                    <span>
                      <svg
                        id="Layer_13"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 128 95.91"
                      >
                        <path
                          d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                          transform="translate(-131.88 -418.11)"
                        ></path>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div> */}
            {/* <div
              className="col-xl-4 col-lg-4 col-md-4"
              data-aos="fade-right"
              data-aos-delay="600"
            >
              <div className="single-price-area mb-30 bgc-2">
                <div className="price-bg">
                  <img src="assets/img/pricing/1.png" alt="pricing-img" />
                </div>
                <div className="price-header">
                  <h2>
                    $88<span className="sep">/</span>
                    <span>month</span>
                  </h2>
                  <h4>premium plan</h4>
                </div>
                <div className="price-icon bgc-1">
                  <svg
                    id="Capa_3"
                    enable-background="new 0 0 611.254 611.254"
                    height="512"
                    viewBox="0 0 611.254 611.254"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <g>
                        <g id="Icons_16_">
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <path d="m162.069 123.335c-3.291 0-6.581-.206-9.872-.823l-9.05-1.234-1.44-8.844c-3.702-21.801 3.085-44.014 18.305-59.85s37.227-23.241 59.028-20.156l9.05 1.234 1.44 8.844c3.702 21.801-3.085 44.014-18.305 59.85-12.958 13.575-30.851 20.979-49.156 20.979zm43.397-66.432c-10.284.823-20.156 5.347-27.56 13.163-7.404 7.61-11.723 17.688-12.34 28.383 10.284-.823 20.156-5.347 27.56-12.957 7.609-7.816 11.723-18.1 12.34-28.589z" />
                                  </g>
                                  <g>
                                    <path d="m103.041 193.058-7.816-4.73c-18.51-10.901-30.645-30.234-32.496-51.623-2.057-21.39 6.376-42.574 22.624-56.765l6.787-5.964 7.816 4.73c18.305 10.901 30.645 30.234 32.496 51.624 2.057 21.39-6.376 42.574-22.418 56.765zm-8.021-86.794c-5.759 8.227-8.433 18.099-7.404 28.177 1.028 10.078 5.347 19.333 12.546 26.326 5.759-8.227 8.433-18.099 7.404-28.177-1.028-10.078-5.553-19.333-12.546-26.326z" />
                                  </g>
                                  <g>
                                    <path d="m78.361 293.014-9.05-.823c-21.39-2.057-40.517-14.397-51.212-32.907-10.695-18.51-11.929-41.34-3.496-61.084l3.702-8.227 9.05.823c21.39 2.057 40.517 14.397 51.212 32.907s11.929 41.34 3.496 61.084zm-43.808-75.276c-1.851 9.872 0 20.156 4.936 29 5.142 8.638 12.957 15.22 22.418 18.51 1.851-9.872 0-20.156-4.936-29-5.142-8.638-12.957-15.219-22.418-18.51z" />
                                  </g>
                                  <g>
                                    <path d="m67.049 400.991c-13.369 0-26.737-4.113-38.049-11.929-17.482-12.135-28.177-32.29-28.794-53.886l-.206-9.049 8.433-3.085c20.156-7.198 42.78-4.113 60.262 8.021 17.482 12.135 28.177 32.29 28.794 53.886l.206 9.05-8.433 3.085c-7.199 2.673-14.809 3.907-22.213 3.907zm-23.858-32.496c8.433 5.759 18.305 8.227 27.971 7.199-2.468-9.667-8.433-18.305-16.659-24.064-8.227-5.759-18.099-8.227-27.971-7.199 2.673 9.667 8.432 18.305 16.659 24.064z" />
                                  </g>
                                  <g>
                                    <path d="m104.481 503.004c-3.702 0-7.404-.411-11.106-1.028-20.978-3.702-39.283-17.276-48.744-36.61l-3.908-8.021 6.376-6.376c15.22-15.014 37.021-22.007 57.999-18.305s39.283 17.276 48.744 36.609l3.908 8.021-6.376 6.376c-12.546 12.547-29.411 19.334-46.893 19.334zm-6.787-25.503c9.872 1.645 19.95-.411 28.588-5.553-6.376-7.816-15.22-12.957-25.092-14.808-9.872-1.645-19.95.411-28.588 5.553 6.17 7.815 15.014 13.163 25.092 14.808z" />
                                  </g>
                                  <g>
                                    <path d="m181.608 579.514c-15.014 0-30.028-5.142-41.957-14.808l-6.993-5.553 3.085-8.432c7.61-20.156 24.269-35.581 44.836-41.34s42.78-1.028 59.645 12.546l6.993 5.553-3.085 8.433c-7.404 20.156-24.269 35.581-44.836 41.34-5.965 1.438-11.724 2.261-17.688 2.261zm-17.894-29c8.844 4.319 19.127 5.347 28.794 2.674 9.667-2.674 18.099-8.844 23.652-17.071-9.05-4.319-19.127-5.347-28.794-2.674-9.872 2.674-18.098 8.844-23.652 17.071z" />
                                  </g>
                                  <g>
                                    <path d="m270.869 539.202c-60.262-10.489-114.148-43.191-152.197-91.729-38.255-48.95-56.971-110.034-52.652-172.147 4.73-66.638 34.758-127.516 84.737-171.53l16.454 18.716c-45.042 39.489-72.191 94.403-76.304 154.459-3.908 55.943 12.957 111.063 47.51 155.076 34.141 43.602 82.68 73.013 136.771 82.474z" />
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <path d="m449.186 123.335c-18.305 0-36.198-7.404-49.155-20.773-15.22-15.631-22.007-38.049-18.305-59.85l1.44-8.844 9.05-1.234c21.801-3.291 43.808 4.319 59.028 20.156 15.22 15.837 22.007 38.049 18.305 59.85l-1.44 8.844-8.844 1.234c-3.497.411-6.788.617-10.079.617zm-43.396-66.432c.617 10.489 4.936 20.773 12.34 28.383 7.404 7.816 17.071 12.34 27.56 13.163-.617-10.489-4.936-20.773-12.34-28.383-7.61-7.815-17.277-12.34-27.56-13.163z" />
                                  </g>
                                  <g>
                                    <path d="m508.214 193.058-6.787-5.964c-16.042-14.191-24.475-35.375-22.418-56.765s14.191-40.723 32.496-51.624l7.816-4.73 6.787 5.964c16.042 14.191 24.475 35.375 22.624 56.765-2.057 21.39-14.191 40.723-32.496 51.623zm8.021-86.794c-6.993 6.993-11.518 16.248-12.546 26.326s1.851 20.156 7.61 28.383c7.198-6.993 11.518-16.042 12.546-26.326.823-10.284-1.851-20.156-7.61-28.383z" />
                                  </g>
                                  <g>
                                    <path d="m532.894 293.014-3.702-8.227c-8.638-19.744-7.404-42.574 3.496-61.084 10.695-18.51 29.822-30.851 51.212-32.907l9.049-.823 3.702 8.227c8.638 19.744 7.404 42.574-3.496 61.084-10.695 18.51-29.822 30.851-51.212 32.907zm43.808-75.276c-9.255 3.291-17.276 9.872-22.418 18.51-5.142 8.844-6.787 19.127-4.936 29 9.255-3.291 17.276-9.667 22.418-18.51 4.936-8.844 6.582-19.128 4.936-29z" />
                                  </g>
                                  <g>
                                    <path d="m544.206 400.991c-7.61 0-15.014-1.234-22.213-3.908l-8.433-3.085.206-9.05c.411-21.595 11.106-41.751 28.794-53.886 17.482-12.34 40.106-15.22 60.262-8.021l8.432 3.085-.206 9.049c-.411 21.596-11.106 41.751-28.794 53.886-11.31 7.817-24.679 11.93-38.048 11.93zm-4.319-25.297c9.872 1.028 19.745-1.44 27.971-7.199 8.227-5.759 14.191-14.397 16.659-24.064-9.872-1.028-19.744 1.44-27.971 7.199-8.226 5.759-13.985 14.397-16.659 24.064z" />
                                  </g>
                                  <g>
                                    <path d="m506.774 503.004c-17.276 0-34.347-6.787-46.893-19.333l-6.376-6.376 3.908-8.021c9.461-19.333 27.56-33.113 48.744-36.609 20.978-3.702 42.78 3.291 57.999 18.305l6.376 6.376-3.908 8.021c-9.461 19.333-27.766 33.113-48.744 36.61-3.702.822-7.404 1.027-11.106 1.027zm-21.595-30.85c8.433 5.142 18.51 7.198 28.383 5.347 9.872-1.645 18.716-6.993 25.092-14.808-8.433-5.142-18.716-7.198-28.588-5.553-9.668 1.851-18.511 7.198-24.887 15.014z" />
                                  </g>
                                  <g>
                                    <path d="m429.647 579.514c-5.964 0-11.723-.823-17.482-2.468-20.567-5.759-37.432-21.184-44.836-41.34l-3.291-8.227 6.993-5.553c16.659-13.574 39.078-18.305 59.645-12.546s37.432 21.184 44.836 41.34l3.085 8.432-6.993 5.553c-11.929 9.667-26.943 14.809-41.957 14.809zm-10.9-26.326c9.872 2.674 19.95 1.851 28.794-2.468-5.553-8.227-13.986-14.603-23.652-17.276-9.667-2.674-19.95-1.645-28.794 2.674 5.553 8.226 13.985 14.396 23.652 17.07z" />
                                  </g>
                                  <g>
                                    <path d="m340.386 539.202-4.319-24.681c54.092-9.461 102.63-38.872 136.771-82.474 34.347-44.014 51.212-99.134 47.51-155.076-4.113-60.056-31.262-114.97-76.304-154.459l16.454-18.716c49.978 44.014 80.006 104.892 84.737 171.53 4.319 62.113-14.397 123.197-52.652 172.147-38.049 48.538-92.141 81.035-152.197 91.729z" />
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <path d="m390.159 406.544-82.474-60.262-82.474 60.262 31.468-97.694-82.269-60.262h101.602l31.468-97.694 31.468 97.694h101.602l-82.269 60.262zm-139.24-132.863 35.17 25.709-13.369 41.546 34.964-25.709 34.964 25.709-13.369-41.546 35.17-25.709h-43.397l-13.369-41.34-13.369 41.34z" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <ul className="price-item-list">
                  <li>
                    <i className="fa fa-check"></i> web development
                  </li>
                  <li>
                    <i className="fa fa-check"></i> mobile development
                  </li>
                  <li>
                    <i className="fa fa-check"></i> advetising
                  </li>
                  <li>
                    <i className="fa fa-check"></i> graphic design
                  </li>
                  <li>
                    <i className="fa fa-check"></i> project management
                  </li>
                </ul>
                <div className="price-btn pt-40">
                  <Link
                    className="btn-5 text-center text-uppercase magic-hover magic-hover__square"
                    to="#"
                  >
                    buy now
                    <span>
                      <svg
                        id="Layer_14"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 128 95.91"
                      >
                        <path
                          d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                          transform="translate(-131.88 -418.11)"
                        ></path>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
