import React, { useEffect } from "react";
import MarketingHero from "./marketingHero";
import MarketingServices from "./marketingservices";
// import VideoComponent from "../../../components/video";
// import MarketingPricing from "./marketingPricing";
// import MarketingAccordion from "./marketingAccordian";
import BrandsComponent from "../../../components/brands";
function ServicesMarketingIndex() {
  useEffect(()=>{
    document.title = "Quantilytics Business Development"
  },[])
  return (
    <>
    <MarketingHero />
    <MarketingServices />
    {/* <VideoComponent /> */}
    {/* <MarketingPricing /> */}
    {/* <MarketingAccordion /> */}
    <BrandsComponent />
    </>
  );
}

export default ServicesMarketingIndex;
