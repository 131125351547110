import React from "react";
import { Link } from "react-router-dom";

function ContactHero() {
  return (
    <div
      id="khalif-breadcrumb-wrape"
      className="khalif-breadcrumb-wrape breadcrumb-bg text-center d-flex align-items-center avatar"
      style={{ backgroundImage: `url('/assets/img/contactbg.jpg')` }}
    >
      <div className="container">
        <div className="row">
          <div className="offset-xl-2 col-xl-8">
            <div className="khalif-breadcrumb-content">
              <h2>contact us</h2>
              <ul className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home 
                  </Link>
                </li>
                <li className="breadcrumb-item active text-white">contact</li>
              </ul>
              <p>
                Contact us anytime with your inquiries, feedback, and questions.
                We're here to assist you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactHero;
