import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

function BlogsArea() {
  const [blogData, setBlogData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const fetchBlogData = async () => {
    try {
      const response = await axios.get("https://affinityapi.dev-sh.xyz/api/blog", {
        headers: {
          Accept: "application/json",
        },
      });

      if (response.data.status === true) {
        setBlogData(response?.data?.data);
      } else {
        toast.error("Data not found");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlogData();
  }, []);

  const filteredBlogData = blogData.filter((blog) =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setModalContent('');
    setShowModal(false);
  };


  return (
    <>
      <div id="khalif-latest-blog-area" className="khalif-latest-blog-area mt-110 mb-110 avatar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <div className="single-sid-wdg sm-mt-30" data-aos="fade-up">
                <form action="#" className="wdg-search-form">
                  <input
                    type="text"
                    placeholder="Type to search here..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button className="submit-btn" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            {filteredBlogData.map((blog) => (
              <div key={blog.id} className="col-md-4">
                <div className="khalif-single-blog-wrape mb-30 magic-hover magic-hover__square avatar" data-aos="fade-up">
                  <img className='image' src={blog.image} alt="Tesla News" />
                  <div className="single-blog-content">
                    {/* <h4>
                      <Link to="#" className="post-title">
                        {blog.title}
                      </Link>
                    </h4> */}

                    {blog.title.length > 14 ? (
                      <h4>
                        <Link to="#" >
                          {blog.title.substring(0, 14)}
                          {blog.title.length > 14 && (
                            <span onClick={() => handleShowModal(blog.title)} className="show-more-link fs-6">
                                 ...Show More
                            </span>
                          )}
                        </Link>
                      </h4>
                    ) : (
                      <h4><Link to="#">{blog.title}</Link></h4>
                    )}

                    {blog.desc.length > 50 ? (
                      <div>
                        <p className='textarea'>
                          {blog.desc.substring(0, 50)}
                          {blog.desc.length > 50 && (
                            <span onClick={() => handleShowModal(blog.desc)} className="show-more-link">
                                 ...Show More
                            </span>
                          )}
                        </p>
                      </div>
                    ) : (
                      <p>{blog.desc}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Blog Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-content-text">{modalContent}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <style>
        {
          `
          img.image{
            height: 230px;
          }
          .modal-content-text {
            color: black;
          }
          .show-more-link {
            color: white; 
            // cursor: pointer;
            text-decoration: underline; 
            margin-left: 8px; 
          }
      
          .show-more-link:hover {
            color: blue; 
          }

          .textarea{
            font-size: 18px !important;
            line-height: 30px;
          }

          
          `
        }
      </style>
    </>
  );
}

export default BlogsArea;
