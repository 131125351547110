import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
// import { useRouter } from 'next/navigation'

function DevelopmentServices() {

  const navigate = useNavigate();

  // const router = useRouter()
  const [data, setData] = useState([]);

  const fetchDevelopmentData = async () => {
    try {
      const userResponse = await axios.get("https://affinityapi.dev-sh.xyz/api/quantilitics", {
        headers: {
          Accept: "application/json",
        },
      });

      console.log(userResponse, "Success");

      if (userResponse.data.status === true) {
        toast.success("Please Check your details");
        setData(userResponse?.data?.data); //
      } else {
        toast.error("Data not found");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDevelopmentData();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setModalContent('');
    setShowModal(false);
  };

  return (
    <>
      <div className="khalif-services-wrap pt-100 pb-100 avatar">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title-wrape text-center text-uppercase position-relative mb-100">
                <h3>Case Studies</h3>
                <h4>Development</h4>
              </div>
            </div>
            <div className="container">
              <div className="row mt-0 ">
                {data.map((item, index) => (
                  <div className="cardBox col-md-3 mb-5" key={index}>
                    <div className="  p-3  card" style={{ textAlign: "left" }}>
                      <button type="button" style={{ background: " #0000" }} onClick={() => navigate(`${item.admin_panel ? `/mobile_development/${item.id}` : `/web_development/${item.id}`}`)} >
                        {/* {item?.all_images?.map((image, imageIndex) => ( */}
                        <img src={item?.all_images[0]} alt={index} className="title " />
                        {/* ))} */}
                        <div class="content text-white">
                          {item.title && <h5 className="">{item?.title} </h5>}

                          {item.description.length > 90 ? (
                            <p>
                              <>
                                {item.description.substring(0, 90)}
                                {item.description.length > 90 && (
                                  <span onClick={() => handleShowModal(item.description)} className="show-more-link fs-6">
                                    ...Show More
                                  </span>
                                )}
                              </>
                            </p>
                          ) : (
                            <h4><button type="button" onClick={() => navigate(`/mobile_development`)}>{item.description}</button></h4>
                          )}
                        </div>
                      </button>
                    </div>
                    {/* 
                {item.images.map((image, imageIndex) => (
                  <div className="col-md-2 cardBox p-4 text-center" key={imageIndex}>
                  <div className="card">
                  <img src={image.value} alt={{imageIndex}} className="image custm p-4" />
                  </div>
                  </div>
                ))} */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Blog Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-content-text text-black">{modalContent}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <style>
        {
          `@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
     
       .cardBox {
         position: relative;
         height: 400px;
         width:278px;
         margin-right:7px;
         display: grid;
         place-items: center;
         overflow: hidden;
         border-radius: 20px;
         box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
           rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
       }
       .card {
         position: absolute;
         width: 95%;
         height: 97%;
         background: #000814;
         border-radius: 20px;
         z-index: 5;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         text-align: center;
         color: #ffffff;
         overflow: hidden;
         padding: 20px;
        //  cursor: pointer;
         box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
           rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
       }
       .card .title {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         font-size: 3rem;
         font-weight: 800;
         pointer-events: none;
        //  opacity: 0.9;
       }
       
       span {
         top: 75%;
       }
       
       .card .content h3 {
         font-size: 3rem;
         padding-bottom: 10px;
       }
       .card .content p {
         font-size: 1.0rem;
         line-height: 25px;
       }
       .card .content {
         transform: translateY(100%);
         opacity: 0;
         transition: 0.3s ease-in-out;
       }
       
       .card:hover .content {
         transform: translateY(0);
         opacity: 1;
       }
       
       .card:hover .title, 
       .card:hover .title {
         opacity: 0;
       }
       
       .cardBox::before {
         content: "";
         position: absolute;
         width: 40%;
         height: 150%;
         background: #40E0D0;
       background: -webkit-linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);
       background: linear-gradient(to right, #fff, #797be9, #000);
         animation: glowing01 5s linear infinite;
         transform-origin: center;
         animation: glowing 5s linear infinite;
       }
       
       @keyframes glowing {
         0% {
           transform: rotate(0);
         }
         100% {
           transform: rotate(360deg);
         }
       }
       
       `
        }
      </style>
    </>
  );
}

export default DevelopmentServices;
